export interface UserEmail {
    email: string;
}

export interface UserEmailPassword extends UserEmail {
    password: string;
}

export interface User extends UserEmailPassword {
    id: number;
    firstName: string;
    secondName: string;
    // phone: string;
};

export enum RecoverUserPwdRequestStatus {
    WithoutStatus,
    OK,
    Error,
    // UserRegistered
}

export enum RegisterUserRequestStatus {
    WithoutStatus = 'WithoutStatus',
    OK = 'OK',
    Error = 'Error',
    UserRegistered = 'UserRegistered'
}

export enum LoginUserFormStatus {
    Login,
    RecoverUserPwd,
    RegisterUser
}
